import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../../components/layout"
import BluePageContent from "../../components/StyledElements/bluePageContent"
import WhitePageContent from "../../components/StyledElements/whitePageContent"
import parse from "html-react-parser"
import { GatsbyImage } from "gatsby-plugin-image"
import MFsquaresBlueTop from "../../components/StyledElements/mfsquaresBlueTop"
import Seo from "gatsby-plugin-wpgraphql-seo"

const WpPage = ({ data }) => {
  const { wpPage } = data

  return (
    <>
      <Seo post={wpPage} />
      <Layout>
        <BluePageContent>
          <h1>{wpPage.title}</h1>
              <Heading>{wpPage.ACFJobPage.heroheadJob.heroHeadingJob}</Heading>
              {parse(`${wpPage.ACFJobPage.heroheadJob.heroTextJob}`)}
        </BluePageContent>
        <WhitePageContent>
          <MFsquaresBlueTop />
          {parse(`${wpPage.ACFJobPage.whiteSectionJob}`)}
        </WhitePageContent>
        <EkspertGrid>
          <GatsbyImage
            image={
              wpPage.ACFJobPage.ekspertenJob.ekstpertimgjob.localFile
                .childImageSharp.gatsbyImageData
            }
            alt={wpPage.ACFJobPage.ekspertenJob.ekstpertimgjob.altText}
          />
          <EkspertDiv>
            {parse(`${wpPage.ACFJobPage.ekspertenJob.eksperttxtjob}`)}
          </EkspertDiv>
        </EkspertGrid>
        <BluePageContent>
          {parse(`${wpPage.ACFJobPage.bottomSectionJob}`)}
        </BluePageContent>
        <WhitePageContent>
          <MFsquaresBlueTop />
          {parse(`${wpPage.ACFJobPage.whiteBottomSectionJob}`)}
        </WhitePageContent>
      </Layout>
    </>
  )
}

export default WpPage

export const query = graphql`
  query JobPageById($id: String) {
    wpPage(id: { eq: $id }) {
      __typename
      id
      uri
      title
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      ACFJobPage {
        bottomSectionJob
        whiteBottomSectionJob
        whiteSectionJob
        heroheadJob {
          heroHeadingJob
          heroTextJob
        }
        ekspertenJob {
          eksperttxtjob
          ekstpertimgjob {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                  height: 480
                )
              }
            }
          }
        }
      }
    }
  }
`

const EkspertGrid = styled.div`
  @media (min-width: 993px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
    padding: 0px;
    position: relative;
    background-color: #25333d;
    color: #eff1ef;
    font-weight: 400;
  }
`

const Heading = styled.h2`
  font-size: 24px;
  font-weight: 500;
  color: #eff1ef;
  max-width: 600px;
  margin-top: 0px !important;
`

const EkspertDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 6em;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.75em;
  background-color: #25333d;
  color: #eff1ef;
  @media (max-width: 993px) {
    padding: 20px;
  }
`
